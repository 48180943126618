<template>
  <div class="product SpotlightProduct">
    <div class="product-image">
      <img v-if="this.checkIsImage(product.imageSrc)" :src="product.imageSrc" />
      <video
        autoplay
        muted
        loop
        v-else-if="this.checkIsVideo(product.imageSrc)"
      >
        <source :src="product.imageSrc" type="video/mp4" />
      </video>
    </div>
    <div class="product-info">
      <h2 class="product-name">{{ product.productName }}</h2>
      <div class="product-price" v-if="product.price != null && this.$store.state.configuration.showPrice" >
        <span class="product-price-label">{{ product.priceLabel }}: </span>
        <span class="product-price-value">{{ formatMoney(price) }}</span>
        <span class="product-discount-label" v-if="product.discountLabel"
          >{{ product.discountLabel }}
        </span>
        <span class="product-discount-percentage" v-if="product.discountLabel">
          {{ discountPercentage }}
        </span>
        <span class="product-discount-value" v-if="product.discountLabel">{{
          formatMoney(product.realPrice)
        }}</span>
      </div>
      <div
        class="product-description"
        v-html="this.textToHtml(product.description)"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseProduct from "./BaseProduct";

export default {
  name: "SpotlightProduct",
  extends: BaseProduct,
  computed: {
    ...mapGetters(["formatMoney"]),
    discountPercentage() {
      return `${Math.round(
        ((this.product.realPrice - this.product.price) /
          this.product.realPrice) *
          100
      )}%`;
    },
  },
  methods: {
    textToHtml(text) {
      return text
        .replaceAll("\n", "<br>")
        .replace(">*", "<strong>")
        .replace("*<", "</strong>");
    },
    checkIsImage(src) {
      return src.match(/\.(jpeg|jpg|gif|png)$/) != null;
    },
    checkIsVideo(src) {
      return src.match(/\.(mp4|mov)$/) != null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/skin.scss";
div.SpotlightProduct {
  display: flex;
  flex-direction: column;
}

.product-image {
  align-self: center;
  img {
    max-height: 30vh;
    text-align: center;
  }
}

.product-description {
  margin-bottom: 16px;
}

.product-price {
  padding-bottom: 1rem;
}

.product-price-label,
.product-price-value {
  font-weight: bold;
}

.product-price-value {
  color: $primary;
  margin-right: 0.2rem;
}

.product-discount-label,
.product-discount-percentage,
.product-discount-value {
  color: gray;
}

.product-discount-value {
  text-decoration: line-through;
}
</style>
