import baseStore from "armory-sdk/src/store";

export const store = {
  state: {
    ...baseStore.state,
    displayProducts: {},
    allProducts: {},
    cart: {},
    modal: null,
    showAllProducts: false,
    selectedProductCategories: [],
    configuration: {},
  },
  getters: {
    ...baseStore.getters,
    cartTotal: (state) => {
      let total = 0;
      for (const id in state.cart) {
        total += state.cart[id] * state.allProducts[id].price;
      }
      return total;
    },
    pickedProducts: (state) => {
      return Object.keys(state.cart).reduce((obj, productId) => {
        if (state.cart[productId] > 0) obj.push(productId);
        return obj;
      }, []);
    },
    shownProducts: (state) => {
      let products = [];
      let filter = false;
      for (const product of Object.values(state.displayProducts)) {
        const categories = product.categoryName.split(";").map((c) => c.trim());
        if(state.configuration.categoryFilterStrategy === "OR"){
          filter = state.selectedProductCategories.some((c) => categories.includes(c))
        } else if (state.configuration.categoryFilterStrategy === "AND") {
          filter = state.selectedProductCategories.every((c) => categories.includes(c))
        }
        if (
          (!state.showAllProducts && product.id in state.cart) ||
          (state.showAllProducts &&
            state.selectedProductCategories.length == 0) ||
          (state.showAllProducts &&
            state.selectedProductCategories && filter
            )
        )
          products.push({
            id: product.id,
            product,
            price: product.price,
            quantity: state.cart[product.id] || 0,
          });
      }
      return products;
    },
    productsByCategory: (state) => (categoryName) => {
      return Object.values(state.displayProducts).reduce((obj, product) => {
        const categories = product.categoryName.split(";").map((c) => c.trim());
        if (categories.includes(categoryName)) obj.push(product);
        return obj;
      }, []);
    },
    productCategories: (state) => {
      return Object.values(state.displayProducts).reduce((obj, product) => {
        let newCategories = [];
        for (let category of product.categoryName.split(";")) {
          category = category.trim();
          if (!obj.includes(category)) newCategories.push(category);
        }
        return [...obj, ...newCategories];
      }, []);
    },
    formatMoney: (state) => (value) => {
      return new Intl.NumberFormat(state.configuration.locale || "hr", {
        style: "currency",
        currency: state.configuration.currency || "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value);
    },
  },
  mutations: {
    ...baseStore.mutations,
    updateAllProducts(state, allProducts) {
      state.allProducts = allProducts.reduce((obj, product) => {
        return { ...obj, [product.id]: product };
      }, state.allProducts);
    },
    setDisplayProducts(state, displayProducts) {
      state.displayProducts = displayProducts.reduce((obj, product) => {
        return { ...obj, [product.id]: product };
      }, {});
    },
    setDefaultCart(state, displayProducts) {
      const hasAnyDefaultQuantity = displayProducts.some(
        (product) => product.isPreference
      );
      state.cart = displayProducts.reduce((obj, product) => {
        if (state.cart[product.id] !== undefined) return obj;
        if (!product.isPreference && hasAnyDefaultQuantity) return obj;
        return {
          ...obj,
          [product.id]: product.defaultQuantity,
        };
      }, state.cart);

      state.inputs = {
        ...state.inputs,
        cart: state.cart,
      };
    },
    setQuantity(state, { id, quantity }) {
      state.cart = {
        ...state.cart,
        [id]: quantity,
      };
    },
    emptyCart(state) {
      state.cart = Object.keys(state.cart).reduce((obj, productId) => {
        if (state.displayProducts[productId] !== undefined)
          return { ...obj, [productId]: 0 };
        return obj;
      }, state.cart);
      state.inputs = {
        ...state.inputs,
        cart: state.cart,
      };
    },
    openModal(state, product) {
      state.modal = product;
    },
    closeModal(state) {
      state.modal = null;
    },
    showAllProducts(state) {
      state.showAllProducts = true;
    },
    setSelectedProductCategories(state, categories) {
      state.selectedProductCategories = categories;
    },
    unshowAllProducts(state) {
      state.showAllProducts = false;
      state.selectedProductCategories = [];
    },
    setConfiguration(state, configuration) {
      state.configuration = configuration;
    },
  },
};
