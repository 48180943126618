<template>
  <div class="component content" id="manage-contacts">
    <div class="manage-contact" v-for="contact in contacts" :key="contact.id">
      <div class="contact-name">
        {{ contact.firstName }} {{ contact.lastName }}
      </div>
      <div class="contact-action">
        <button
          class="button is-small is-primary is-inverted"
          @click="sendReminder(contact.id)"
        >
          Send reminder
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ManageContacts",
  props: {
    tenantId: {
      type: String,
      required: true,
    },
    contacts: {
      type: Array,
      required: true,
    },
  },
  methods: {
    sendReminder(contactId) {
      this.$socket.send(
        JSON.stringify({
          type: "template_submit",
          screenId: this.$store.state.activeScreenId,
          data: {
            submit: "send-order-reminder",
            contactId,
            tenantId: this.tenantId,
          },
        })
      );
    },
  },
};
</script>

<style scoped>
.manage-contact {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
}

.button {
  box-shadow: unset !important;
}
</style>
