<template>
  <div class="product">
    <div v-if="product.imageSrc" class="product-image" @click="openModal">
      <img :src="product.imageSrc" />
    </div>
    <div class="product-info">
      <div class="product-name">{{ product.productName }}</div>
      <div class="product-description">{{ product.description }}</div>
      <div class="product-price">
        {{ formatMoney(price) }}
      </div>
    </div>
    <div class="product-quantity">
      <input
        type="number"
        v-model="selectedQuantity"
        @focus="$event.target.select()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "BaseProduct",
  props: {
    id: {
      type: String,
      required: true,
    },
    price: {
      type: Number,
      required: false,
    },
    product: {
      type: Object,
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
  },
  computed: {
    selectedQuantity: {
      get() {
        return this.$store.state.cart[this.id] || 0;
      },
      set(value) {
        value = Math.floor(value);
        this.$store.commit("setQuantity", {
          id: this.id,
          quantity: Math.max(value, 0),
        });
        this.$store.commit("setInputValue", {
          inputId: "cart",
          value: this.$store.state.cart,
        });
      },
    },
    ...mapGetters(["formatMoney"]),
  },
  methods: {
    openModal() {
      this.$store.commit("openModal", {
        product: this.product,
        id: this.id,
        price: this.price,
        quantity: this.quantity,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/skin.scss";

.product {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.product-image {
  height: 4rem;
  min-height: 4rem;
  width: 3rem;
  min-width: 3rem;
  text-align: center;
  position: relative;
  display: flex;
  place-items: center;
  justify-content: center;
}

.product-image img {
  height: auto;
  width: auto;
  max-height: 100%;
  max-width: 100%;
}

.product-info {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  line-height: 1rem;
  gap: 0.2rem;
}

.product-quantity input[type="number"] {
  width: 3.5em;
  font-size: 1rem;
  text-align: center;
  border-radius: 30px;
  border: 1px solid gray;
  line-height: 2.2rem;
}

.product-name {
  font-weight: bold;
  color: $primary;
}
</style>
