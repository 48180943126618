<template>
  <div :class="clses">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content box" v-if="spec">
      <b-carousel
        :has-drag="true"
        :repeat="true"
        :animated="'slide'"
        :autoplay="false"
        :arrow="true"
        :arrow-hover="false"
        :icon-prev="'angle-left'"
        :icon-next="'angle-right'"
        :icon-size="'is-large'"
        :indicator="false"
        v-model="currentIndex"
      >
        <b-carousel-item v-for="(spec, i) in this.carousels" :key="i">
          <div class="carousel-content">
            <div class="modal-content-inner">
              <div class="modal-image">
                <img :src="spec.product.imageSrc" />
              </div>
              <div class="modal-title">
                <h2 draggable="true">{{ spec.product.productName }}</h2>
              </div>
              <div class="modal-description">
                <span draggable="true">{{ spec.product.longDescription }}</span>
              </div>
              <div class="modal-price" draggable="true" v-if="spec.price != null && configuration.showPrice">
                <span>{{ formatMoney(spec.price) }}</span>
              </div>
            </div>
            <div class="carousel-placement">
              {{ i + 1 }} / {{ carousels.length }}
            </div>
            <div class="modal-actions">
              <input
                type="number"
                v-model="selectedQuantity"
                @focus="$event.target.select()"
              />
              <button class="button is-primary" @click="closeModal">
                Save
              </button>
            </div>
          </div>
        </b-carousel-item>
      </b-carousel>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProductModal",
  data() {
    return {
      currentIndex: 0,
    };
  },
  computed: {
    ...mapGetters(["formatMoney"]),
    clses() {
      if (this.$store.state.modal != null) {
        return "modal is-active";
      } else {
        return "modal";
      }
    },
    configuration() {
      return this.$store.state.configuration;
    },
    carousels() {
      let carouselItems = [this.spec];
      const categoryProducts = this.productsByCategory()(
        this.spec.product.categoryName
      );
      for (const product of categoryProducts) {
        if (product.id !== this.spec.product.id) {
          carouselItems.push({
            id: product.id,
            product,
            price: product.price,
            quantity: this.$store.state.cart[product.id],
          });
        }
      }
      return carouselItems;
    },
    currentCarousel() {
      return this.carousels[this.currentIndex];
    },
    spec() {
      return this.$store.state.modal;
    },
    selectedQuantity: {
      get() {
        return this.$store.state.cart[this.currentCarousel.product.id] || 0;
      },
      set(value) {
        this.$store.commit("setQuantity", {
          id: this.currentCarousel.id,
          quantity: Math.max(value, 0),
        });
        this.$store.commit("setInputValue", {
          inputId: "cart",
          value: this.$store.state.cart,
        });
      },
    },
  },
  methods: {
    ...mapGetters(["productsByCategory"]),
    closeModal() {
      this.currentIndex = 0;
      this.$store.commit("closeModal");
    },
    reduceQuantity() {
      this.selectedQuantity = this.selectedQuantity - 1;
    },
    increaseQuantity() {
      this.selectedQuantity = this.selectedQuantity + 1;
    },
    previousProduct() {},
    nextProduct() {},
  },
};
</script>

<style scoped>
.modal-content {
  max-width: 640px !important;
  max-height: calc(100vh - 40px);
  height: auto;
  width: 100%;
}

.carousel-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.modal-content-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  height: 25rem;
  overflow: auto;
}

.modal-image {
  align-self: center;
}

.modal-description {
  line-height: 1.3;
}

.modal-image img {
  max-height: 10rem;
  text-align: center;
}
.modal-title h2 {
  font-weight: bold;
  font-size: 1.2rem;
}

.modal-price {
  font-weight: bold;
}

.carousel-placement {
  position: absolute;
  bottom: 0rem;
  left: 4rem;
  height: 3rem;
  display: flex;
  align-items: center;
}

.modal-actions {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

input {
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  text-align: center;
  border-radius: 30px;
  border: 1px solid gray;
}

::v-deep div.carousel-arrow {
  position: absolute;
  bottom: 1.5rem;
}

::v-deep div.carousel-arrow span.icon {
  background: transparent;
  border: transparent;
}

::v-deep div.carousel-arrow .has-icons-left {
  left: -1rem !important;
}

::v-deep div.carousel-arrow .has-icons-right {
  left: 1rem !important;
  right: unset;
}

::v-deep span.icon:hover {
  border: unset;
}
</style>
