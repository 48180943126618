<template>
  <div class="component content" id="sum-total" v-if="configuration.showPrice">
    <span id="sum-total-label">{{ label }}: </span>
    <span id="sum-total-value">
      {{ formatMoney(cartTotal) }} {{ configuration.totalLabel }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "CartTotal",
  props: {
    label: String,
  },
  computed: {
    ...mapGetters(["cartTotal", "formatMoney"]),
    configuration() {
      return this.$store.state.configuration;
    },
  },
};
</script>

<style scoped>
.content {
  margin-bottom: 0.5rem;
  padding-top: 0.2rem;
}
</style>
