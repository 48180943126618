<template>
  <div class="product SimplifiedProduct">
    <div class="product-info">
      <div class="product-name">{{ product.productName }}</div>
      <div class="product-price" v-if="price != null && this.$store.state.configuration.showPrice">
        {{ formatMoney(price) }}
      </div>
    </div>
    <div class="product-quantity">
      <input
        type="number"
        v-model="selectedQuantity"
        @focus="$event.target.select()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseProduct from "./BaseProduct";

export default {
  name: "SimplifiedProduct",
  extends: BaseProduct,
  methods: {
    ...mapGetters(["formatMoney"]),
  },
};
</script>
