<template>
  <div id="products-overview" class="template">
    <div class="upper-group">
      <div class="component content">
        <Header v-bind="header"></Header>
      </div>
      <div class="component content">
        <ProductList
          :showProductsAs="'detailed'"
          :actionTexts="actionTexts"
          :showCategoryHeaders="this.$store.state.configuration.showCategoryHeaders"
        ></ProductList>
      </div>
    </div>
    <div class="lower-group">
      <div class="component content">
        <CartTotal :label="cartLabel.label"></CartTotal>
        <Submit v-bind="submit"></Submit>
      </div>
    </div>
    <ProductModal />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Header from "armory-sdk/src/components/Header";
import BaseTemplate from "armory-sdk/src/templates/BaseTemplate";
import Submit from "armory-sdk/src/components/SubmitButton";
import ProductList from "../components/ProductList";
import CartTotal from "../components/CartTotal";
import ProductModal from "../components/ProductModal";

export default {
  name: "ProductsListTemplate",
  extends: BaseTemplate,
  components: {
    Header,
    ProductList,
    Submit,
    CartTotal,
    ProductModal,
  },
  props: {
    header: Object,
    displayProducts: Object,
    actionTexts: Object,
    showCategoryHeaders: Object,
    cartLabel: Object,
    submit: Object,
  },
  computed: {
    ...mapGetters(["pickedProducts"]),
    activeScreenId() {
      return this.$store.state.activeScreenId;
    },
  },
  watch: {
    pickedProducts(newValue) {
      const lastScreen =
        this.$store.state.activeScreenId ===
        this.$store.state.configuration.lastProductListScreen;
      this.$store.commit("setInputValidation", {
        inputId: "cart",
        valid: !lastScreen || newValue.length > 0,
      });
    },
  },
  mounted() {
    const lastScreen =
      this.$store.state.activeScreenId ===
      this.$store.state.configuration.lastProductListScreen;
    this.$store.commit("updateAllProducts", this.displayProducts.products);
    this.$store.commit("setDisplayProducts", this.displayProducts.products);
    this.$store.commit("setDefaultCart", this.displayProducts.products);
    this.$store.commit("setInputValidation", {
      inputId: "cart",
      valid: !lastScreen || this.pickedProducts.length > 0,
    });
  },
};
</script>
