<template>
  <div id="product-actions-parent" class="component content">
    <div id="product-actions">
      <div class="display-actions">
        <button
          class="button is-small is-responsive"
          :class="{ 'is-primary': this.productDisplay === 'cart' }"
          @click="this.selectShowCart"
        >
          {{ actionTexts.defaultOrder }}
        </button>
        <button
          class="button is-small is-responsive"
          @click="this.selectShowAllProducts"
          :class="{ 'is-primary': this.productDisplay === 'all' }"
        >
          {{ actionTexts.showAll }}
        </button>
      </div>
      <div class="quantity-actions">
        <button class="button is-small is-responsive" @click="this.emptyCart">
          {{ actionTexts.clearAll }}
        </button>
      </div>
    </div>
    <div
      id="category-actions"
      :class="{
        hidden:
          this.productDisplay !== 'all' || this.productCategories.length <= 1,
      }"
    >
      <button
        class="button is-small is-responsive"
        @click="toggleCategory(category)"
        v-for="category in productCategories"
        :key="category"
        :class="
          selectedCategories.includes(category) ? 'is-primary' : 'is-light'
        "
      >
        {{ category }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "ProductActions",
  props: {
    actionTexts: Object,
  },
  data() {
    return {
      productDisplay: "cart",
      selectedCategories: [],
    };
  },
  beforeMount() {
    if (this.$store.state.configuration !== undefined && this.$store.state.configuration.isCatalog) {
      this.selectShowAllProducts();      
    }
  },
  computed: {
    ...mapGetters(["productCategories"]),
  },
  methods: {
    ...mapMutations(["showAllProducts"]),
    emptyCart() {
      this.$store.commit("emptyCart");
    },
    toggleCategory(category) {
      if (this.selectedCategories.includes(category))
        this.selectedCategories.splice(
          this.selectedCategories.indexOf(category),
          1
        );
      else this.selectedCategories.push(category);
      this.$store.commit(
        "setSelectedProductCategories",
        this.selectedCategories
      );
    },
    selectShowCart() {
      this.productDisplay = "cart";
      this.selectedCategories = [];
      this.$store.commit("unshowAllProducts");
    },
    selectShowAllProducts() {
      this.productDisplay = "all";
      this.selectedCategories = [];
      this.showAllProducts();
    },
  },
};
</script>

<style scoped>
#product-actions-parent {
  margin-bottom: 1.5rem;
}

#product-actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

#product-actions > div {
  display: flex;
  gap: 0.2rem;
}

#category-actions {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
}

button {
  box-shadow: none !important;
  border-radius: 30px !important;
}
</style>
