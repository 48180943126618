<template>
  <div id="promotion-overview" class="template">
    <div class="upper-group">
      <div class="component content">
        <Header v-bind="header"></Header>
      </div>
      <div class="component content">
        <SpotlightProduct
          :id="product.id"
          :price="product.price"
          :quantity="0"
          :product="product"
        ></SpotlightProduct>
      </div>
    </div>
    <div class="lower-group">
      <CartTotal :label="cartLabel.label"></CartTotal>
      <div class="add-to-cart SubmitButton-parent">
        <input
          type="number"
          min="0"
          step="1"
          v-model="selectedQuantity"
          @focus="$event.target.select()"
          v-if="addToCart.editableQuantity === true"
        />
        <button class="button is-primary is-fullwidth" @click="this.submitCart">
          <span>{{ addToCart.add }}</span>
        </button>
      </div>
      <div class="submit SubmitButton-parent">
        <Submit v-bind="submit" ref="submitButton"></Submit>
      </div>
    </div>
  </div>
</template>
<script>
import BaseTemplate from "armory-sdk/src/templates/BaseTemplate";
import Header from "armory-sdk/src/components/Header";
import Submit from "armory-sdk/src/components/SubmitButton";
import CartTotal from "../components/CartTotal";
import SpotlightProduct from "../components/SpotlightProduct";

export default {
  name: "ProductSpotlightTemplate",
  extends: BaseTemplate,
  components: {
    Header,
    Submit,
    CartTotal,
    SpotlightProduct,
  },
  data() {
    return {
      selectedQuantity: 1,
    };
  },
  props: {
    header: Object,
    product: Object,
    addToCart: Object,
    cartLabel: Object,
    submit: Object,
  },
  mounted() {
    this.$store.commit("setQuantity", {
      id: this.product.id,
      quantity: 0,
    });
    this.$store.commit("setInputValue", {
      inputId: "cart",
      value: this.$store.state.cart,
    });

    this.$store.commit("updateAllProducts", [this.product]);
    this.$store.commit("setDisplayProducts", [this.product]);
    this.$store.commit("setDefaultCart", [this.product]);
  },
  methods: {
    submitCart() {
      this.$store.commit("setQuantity", {
        id: this.product.id,
        quantity: Math.max(this.selectedQuantity, 0),
      });
      this.$store.commit("setInputValue", {
        inputId: "cart",
        value: this.$store.state.cart,
      });
      this.$refs.submitButton.clicked();
    },
    processText(text) {
      return text
        .replaceAll("\n", "<br>")
        .replace(">*", "<strong>")
        .replace("*<", "</strong>");
    },
  },
};
</script>

<style scoped lang="scss">
div.add-to-cart {
  display: flex;

  input {
    width: 3rem;
    height: 3rem;
    margin-right: 0.5rem;
    font-size: 1rem;
    text-align: center;
    border-radius: 30px;
    border: 1px solid gray;
  }

  div.SubmitButton {
    flex-grow: 1;
  }
}

.SubmitButton-parent:not(:last-child) {
  margin-bottom: 0.75rem;
}
</style>
