<template>
  <div class="LabeledText">
    <div class="label">{{ label }}</div>
    <div v-html="text"></div>
  </div>
</template>

<script>
export default {
  name: "LabeledText",
  props: {
    label: String,
    text: String,
  },
};
</script>

<style scoped>
.label {
  font-weight: bold;
}
</style>
