<template>
  <div id="products-list-parent" class="component content">
    <ProductActions
      v-if="showProductActions"
      :actionTexts="actionTexts"
    ></ProductActions>
    <div id="products-list">
      <div
        v-for="(product, index) in this.shownProducts"
        :key="product.id"
        v-bind="product"
        class="product"
      >
        <div
          class="product-header"
          v-if="isFirstInCategory(index, product.product.categoryName) && showCategoryHeaders"
        >
          {{ product.product.categoryName }}
        </div>
        <DetailedProduct
          v-if="showProductsAs === 'detailed'"
          v-bind="product"
        ></DetailedProduct>
        <SimplifiedProduct
          v-else-if="showProductsAs === 'simplified'"
          v-bind="product"
        ></SimplifiedProduct>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DetailedProduct from "./DetailedProduct";
import SimplifiedProduct from "./SimplifiedProduct";
import ProductActions from "./ProductActions";

export default {
  name: "ProductList",
  props: {
    actionTexts: Object,
    showProductActions: {
      default: true,
      type: Boolean,
      required: false,
    },
    showProductsAs: {
      default: "detailed",
      required: false,
    },
    showCategoryHeaders: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DetailedProduct,
    SimplifiedProduct,
    ProductActions,
  },
  methods: {
    isFirstInCategory(index, categoryName) {
      const firstIndexInCategory = this.shownProducts.findIndex(
        (product) => product.product.categoryName === categoryName
      );
      return index === firstIndexInCategory;
    },
  },
  computed: {
    ...mapGetters(["shownProducts"]),
  },
};
</script>

<style scoped>
#products-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.product-header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #4a4a4a;
  border-bottom: 1px solid #4a4a4a;
}
</style>
