<template>
  <div class="product DetailedProduct">
    <div v-if="product.imageSrc" class="product-image" @click="openModal">
      <img :src="product.imageSrc" />
    </div>
    <div class="product-info" @click="openModal">
      <div class="product-name">{{ product.productName }}</div>
      <div class="product-description">{{ product.description }}</div>
      <div class="product-price"  v-if="price != null && this.$store.state.configuration.showPrice">
        {{ formatMoney(price) }}
      </div>
    </div>
    <div class="product-quantity">
      <input
        type="number"
        v-model="selectedQuantity"
        @focus="$event.target.select()"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BaseProduct from "./BaseProduct";

export default {
  name: "DetailedProduct",
  extends: BaseProduct,
  computed: {
    ...mapGetters(["formatMoney"]),
  },
};
</script>
