<script>

import Armory from "armory-sdk/src/Armory";
import ProductsListTemplate from "./templates/ProductsListTemplate";
import ProductSpotlightTemplate from "./templates/ProductSpotlightTemplate";
import ManageContacts from "./components/ManageContacts";
import SummaryTemplate from "./templates/SummaryTemplate";
import TablePreview from "./components/TablePreview";

export default {
  name: "App",
  extends: Armory,
  components: {
    ProductsListTemplate,
    ProductSpotlightTemplate,
    SummaryTemplate,
  },
  methods: {
    getCustomComponents() {
      return {
        ManageContacts,
        TablePreview
      };
    },
    preReceivedSpecificationTrigger(receivedData) {
      if (receivedData.configuration !== undefined && receivedData.configuration !== null) {
        this.$store.commit("setConfiguration", receivedData.configuration);
        if (receivedData.configuration.customCss !== undefined) {
          const style = document.createElement("style");
          style.innerHTML = receivedData.configuration.customCss;
          document.head.appendChild(style);
        }
        const favicon = this.$store.state.configuration.favicon;
        if (favicon != undefined)
          document.getElementById("favicon").href = favicon;

        const webTitle = this.$store.state.configuration.webTitle;
        if (webTitle != undefined)
          document.getElementById("website-title").text = webTitle;
      }
    },
  },
};
</script>
