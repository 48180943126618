<template>
  <div id="summary-template" class="template">
    <div class="upper-group">
      <div class="component content">
        <Header v-bind="header"></Header>
      </div>
      <div class="component content">
        <Title v-bind="title"></Title>
      </div>
      <div
        class="component content"
        id="products-summary-parent"
        v-if="address.showCartReview === true"
      >
        <ProductList
          :displayProducts="Object.values(cart)"
          :showProductsAs="'simplified'"
          :showProductActions="false"
        ></ProductList>
      </div>
      <div class="component content">
        <div
          class="summary-info"
          id="total"
          v-if="address.showCartReview === true && configuration.showPrice"
        >
          <strong>Total</strong>
          <div class="total">{{ formatMoney(cartTotal) }}</div>
        </div>
        <LabeledText
          v-bind="address"
          v-if="address.showCartReview === true"
        ></LabeledText>
      </div>
      <div class="component content">
        <TextArea v-bind="note"></TextArea>
      </div>
    </div>
    <div class="lower-group">
      <div class="component content">
        <CartTotal
          v-if="address.showCartReview === false"
          :label="cartLabel.label"
        ></CartTotal>
        <Submit v-bind="finish"></Submit>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTemplate from "armory-sdk/src/templates/BaseTemplate";
import Header from "armory-sdk/src/components/Header";
import Title from "armory-sdk/src/components/Title";
import Submit from "armory-sdk/src/components/SubmitButton";
import TextArea from "armory-sdk/src/components/TextArea";
import ProductList from "../components/ProductList";
import LabeledText from "../components/LabeledText";
import CartTotal from "../components/CartTotal";
import { mapGetters } from "vuex";

export default {
  name: "SummaryTemplate",
  extends: BaseTemplate,
  components: {
    Header,
    Title,
    Submit,
    TextArea,
    LabeledText,
    ProductList,
    CartTotal,
  },
  props: {
    header: Object,
    title: Object,
    finish: Object,
    address: Object,
    cartLabel: Object,
    note: Object,
  },
  computed: {
    ...mapGetters(["activeScreen", "cartTotal", "formatMoney"]),
    cart() {
      return this.$store.state.cart;
    },
    configuration() {
      return this.$store.state.configuration;
    },
  },
  mounted() {
    const displayProducts = Object.keys(this.cart).reduce(
      (displayProducts, productId) => {
        if (this.$store.state.cart[productId] > 0)
          displayProducts.push(this.$store.state.allProducts[productId]);
        return displayProducts;
      },
      []
    );
    this.$store.commit("setDisplayProducts", displayProducts);
    this.$store.commit("unshowAllProducts");
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/css/skin.scss";

#products-summary-parent {
  padding-bottom: 1.5rem;
  border-bottom: $primary-light 1px solid;
}

#total {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

::v-deep textarea {
  min-height: 12rem !important;
}
</style>
