import { render, staticRenderFns } from "./SpotlightProduct.vue?vue&type=template&id=663f3496&scoped=true"
import script from "./SpotlightProduct.vue?vue&type=script&lang=js"
export * from "./SpotlightProduct.vue?vue&type=script&lang=js"
import style0 from "./SpotlightProduct.vue?vue&type=style&index=0&id=663f3496&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "663f3496",
  null
  
)

export default component.exports